import React from 'react';
import { Box, Link, VStack, Text, Flex } from '@chakra-ui/react';

const PaperListId = ({ papers, paperIds }) => {
  const filteredPapers = papers.filter(paper => paperIds.includes(paper.id));

  return (
    <VStack align="stretch" spacing={4}>
      {filteredPapers.map((paper) => (
        <Box key={paper.id}>
          <Flex alignItems="baseline">
            <Text as="span" fontWeight="400" fontSize="16px" mr={2}>
              [{paper.id}]
            </Text>
            <Box flex="1">
              <Text fontWeight="400" fontSize="18px">
                <Link href={paper.links.pub} isExternal color="teal.300" fontFamily="Roboto">
                  {paper.title}
                </Link>,
              </Text>
              <Text fontSize="18px">
                {paper.authors.map((author, index, array) => {
                  const isLast = index === array.length - 1;
                  const prefix = isLast && index !== 0 ? " and " : "";
                  const suffix = isLast ? "" : ", ";
                  return (
                    <React.Fragment key={index}>
                      {prefix}
                      {/* add a condition to check if is corresponding author using paper.is_corresponding 
                      if yese, add a * to the end of the name*/}
                      {
                        paper.is_corresponding && author === 'Hang Zhang' ?
                          <span style={{ color: "#EEE" }}><b>{author}*</b></span> :
                          author === 'Hang Zhang' ?
                            <span style={{ color: "#EEE" }}><b>{author}</b></span> :
                            author
                      }
                      {/* {author === 'Hang Zhang' ? <span style={{ color: "#EEE" }}><b>{author}</b></span> : author} */}
                      {suffix}
                    </React.Fragment>
                  );
                })}
              </Text>
              <Text as="span" fontSize="18px">
                <i>
                  {paper.publication}
                  {paper.abbreviation && <b> ({paper.abbreviation})</b>}
                </i>
                {paper.location && `, ${paper.location}`}
                {paper.notes && (
                  <Text as="span" color={paper.notesColor} fontWeight={paper.notesBold ? '800' : '400'}>
                    {` (${paper.notes})`}
                  </Text>
                )}
              </Text>
              <Text fontSize="18px">
                {paper.links.pdf && (
                  <Link href={paper.links.pdf} color="teal.300" isExternal mr={2}>
                    [pdf]
                  </Link>
                )}
                {paper.links.slides && (
                  <Link href={paper.links.slides} color="teal.300" isExternal mr={2}>
                    [slides]
                  </Link>
                )}
                {paper.links.poster && (
                  <Link href={paper.links.poster} color="teal.300" isExternal mr={2}>
                    [poster]
                  </Link>
                )}
                {paper.links.post && (
                  <Link href={paper.links.post} color="#FF7F7F" isExternal mr={2}>
                    [post]
                  </Link>
                )}
                {paper.links.code && (
                  <Link href={paper.links.code} color="teal.100" isExternal>
                    [code]
                  </Link>
                )}
              </Text>
            </Box>
          </Flex>
        </Box>
      ))}
    </VStack>
  );
};

export default PaperListId;
