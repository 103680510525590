import {
	Link
  } from "@chakra-ui/react"

// CVPR.js
export const CVPR = () => <Link href="https://cvpr.thecvf.com/" color="teal.300">CVPR</Link>;

export const ECCV = () => <Link href="https://eccv2024.ecva.net/" color="teal.300">ECCV</Link>;

// IJCAI.js
export const IJCAI = () => <Link href="https://www.ijcai.org/" color="teal.300">IJCAI</Link>;

// NeurIPS.js
export const NeurIPS = () => <Link href="https://nips.cc/" color="teal.300">NeurIPS</Link>;

// ICML.js
export const ICML = () => <Link href="https://icml.cc/" color="teal.300">ICML</Link>;

// ECML.js
export const ECML = () => <Link href="https://2024.ecmlpkdd.org/" color="teal.300">ECML</Link>;

// WACV.js
export const WACV = () => <Link href="https://wacv2024.thecvf.com/" color="teal.300">WACV</Link>;

// MIDL.js
export const MIDL = () => <Link href="https://www.midl.io/" color="teal.300">MIDL</Link>;

// MICCAI.js
export const MICCAI = () => <Link href="http://www.miccai.org/" color="teal.300">MICCAI</Link>;

// TCAD.js
export const TCAD = () => <Link href="https://ieee-ceda.org/publication/ieee-transactions-computer-aided-design-integrated-circuits-systems-tcad" color="teal.300">TCAD</Link>;

// TCSVT.js
export const TCSVT = () => <Link href="https://ieee-cas.org/publication/tcsvt" color="teal.300">TCSVT</Link>;

// JMRI.js
export const JMRI = () => <Link href="https://onlinelibrary.wiley.com/journal/15222586" color="teal.300">JMRI</Link>;


export const ISBI = () => <Link href="https://biomedicalimaging.org/2024/" color="teal.300">ISBI</Link>;


export const FCCM = () => <Link href="https://www.fccm.org/" color="teal.300">FCCM</Link>;


export const ISPD = () => <Link href="https://ispd.cc/" color="teal.300">ISPD</Link>;
