import React from 'react';
import {
	Box,
	Center,
    Text,
    Link,
} from "@chakra-ui/react"

import { CVPR, IJCAI, NeurIPS, ICML, ECML, WACV, ECCV, MIDL, MICCAI, TCAD, TCSVT, JMRI } from "../../conferences"

function Services(props) {

    const lfs = props.fontSizes['large'];

	return (
		<Center 
			w="100%" 
			bg="#1F1F21"
			position="relative"
        >
            <Box
                h="100%"
                w="100%"
                maxWidth="928px"
                // bg="#000000"
                // borderTop="3px solid rgba(255, 255, 255, 0.1)"
                margin="36px 24px 0 24px"
                fontFamily="Catamaran"
            >
                <Text position="relative" fontSize={lfs} lineHeight="24px" color="#FFFFFF" fontWeight="500" margin="0 0 10px 10px">
                    Services
                </Text>
                <Box h="3px" w="100%" bg="rgba(255, 255, 255, 0.1)" />
               <Box>
                    <Text color="#FFF" fontWeight="500" fontSize="20px" margin="10px 0 0 15px">
                        Teaching Assistant
                    </Text>
                    <Box h="2px" w="calc(100% - 10px)" bg="rgba(255, 255, 255, 0.1)" display="flex" justifyContent="center" alignItems="center" m="auto" />
                    <Box color="#BBB"  margin="20px 0px 0px 0px" fontFamily="Catamaran">
                        <Text color="#BBB"  fontWeight="400" fontSize="18px" margin="10px 0 0 15px" lineHeight="24px">
                            2015-2016  fall  CUHK  ENGG 2440A  Discrete Mathematics for Engineers
                        </Text>
                        <Text color="#BBB"  fontWeight="400" fontSize="18px" margin="0px 0 0 15px" lineHeight="24px">
                            2018-2019  fall  Cornell  ECE 2720  Data Science for Engineers
                        </Text>
                    </Box>
                </Box>
                <Box>
                    <Text color="#FFF" fontWeight="500" fontSize="20px" margin="10px 0 0 15px">
                        Reviewer / Program Committee
                    </Text>
                    <Box h="2px" w="calc(100% - 10px)" bg="rgba(255, 255, 255, 0.1)" display="flex" justifyContent="center" alignItems="center" m="auto" />
                    <Box color="#BBB"  margin="20px 0px 0px 0px" fontFamily="Catamaran">
                        <Text color="#BBB" fontWeight="400" fontSize="18px" margin="10px 0 0 15px" lineHeight="24px">
                        <MICCAI/> 2020-2024, <CVPR/>/<ECCV/> 2024, <IJCAI/> 2023-2024, <NeurIPS/> 2022,2024, <ICML/>/<ECML/> 2022, <WACV/> 2021-2024
                        </Text>
                        <Text color="#BBB" fontWeight="400" fontSize="18px" margin="0 0 0 15px" lineHeight="24px">
                            <TCAD/>, <TCSVT/>, <JMRI/>
                        </Text>
                        {/* <Text color="#BBB" fontWeight="400" fontSize="18px" margin="10px 0 0 15px" lineHeight="24px">
                            Computer Vision and Pattern Recognition (<CVPR/>) 2024
                        </Text>
                        <Text color="#BBB" fontWeight="400" fontSize="18px" margin="0px 0 0 15px" lineHeight="24px">
                            International Joint Conference on Artificial Intelligence (<IJCAI></IJCAI>) 2023-2024
                        </Text>
                        <Text color="#BBB" fontWeight="400" fontSize="18px" margin="0 0 0 15px" lineHeight="24px">
                            Advances in Neural Information Processing Systems (<NeurIPS/>) 2022
                        </Text>
                        <Text color="#BBB" fontWeight="400" fontSize="18px" margin="0 0 0 15px" lineHeight="24px">
                            International Conference on Machine Learning (<ICML/>) 2022
                        </Text>
                        <Text color="#BBB" fontWeight="400" fontSize="18px" margin="0 0 0 15px" lineHeight="24px">
                            European Conference on Machine Learning (<ECML/>) 2022
                        </Text>
                        <Text color="#BBB" fontWeight="400" fontSize="18px" margin="0 0 0 15px" lineHeight="24px">
                            IEEE Winter Conference on Applications of Computer Vision (<WACV/>) 2021-2024
                        </Text>
                        <Text color="#BBB" fontWeight="400" fontSize="18px" margin="0 0 0 15px" lineHeight="24px">
                            International Conference on Medical Imaging with Deep Learning (<MIDL/>) 2021-2023
                        </Text>
                        <Text color="#BBB" fontWeight="400" fontSize="18px" margin="0 0 0 15px" lineHeight="24px">
                            International Conference on Medical Image Computing and Computer Assisted Intervention (<MICCAI/>) 2020-2023
                        </Text>
                        <Text color="#BBB" fontWeight="400" fontSize="18px" margin="0 0 0 15px" lineHeight="24px">
                            IEEE Transactions on Computer-Aided Design of Integrated Circuits and Systems (<TCAD/>)
                        </Text>
                        <Text color="#BBB" fontWeight="400" fontSize="18px" margin="0 0 0 15px" lineHeight="24px">
                            IEEE Transactions on Circuits and Systems for Video Technology (<TCSVT/>)
                        </Text>
                        <Text color="#BBB" fontWeight="400" fontSize="18px" margin="0 0 0 15px" lineHeight="24px">
                            Journal of Magnetic Resonance Imaging (<JMRI/>)
                        </Text> */}
                    </Box>
                </Box>
            </Box >
		</Center>
	)
}

export default Services;