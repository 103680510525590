import React from 'react';
import {
	Box,
	Center,
    Text,
    Link,
} from "@chakra-ui/react"

import {ISBI, FCCM, ISPD} from "../../conferences"

function SelectedAwards(props) {

    const lfs = props.fontSizes['large'];

	return (
		<Center 
			w="100%" 
			bg="#1F1F21"
			position="relative"
        >
            <Box
                h="100%"
                w="100%"
                maxWidth="928px"
                // bg="#000000"
                // borderTop="3px solid rgba(255, 255, 255, 0.1)"
                margin="36px 24px 0 24px"
                fontFamily="Catamaran"
            >
                <Text position="relative" fontSize={lfs} lineHeight="24px" color="#FFFFFF" fontWeight="500" margin="0 0 10px 10px">
                    Selected Honors & Awards
                </Text>
                <Box h="3px" w="100%" bg="rgba(255, 255, 255, 0.1)" />
                <Box >
                    <Text color="#BBB" fontWeight="400" fontSize="18px" margin="10px 0 0 15px" lineHeight="24px">
                        Best Paper Award Finalist at <ISBI/> 2021.
                    </Text>
                    <Text color="#BBB" fontWeight="400" fontSize="18px" margin="0 0 0 15px" lineHeight="24px">
                        Best Short Paper Award at <FCCM/> 2018.
                    </Text>
                    <Text color="#BBB" fontWeight="400" fontSize="18px" margin="0 0 0 15px" lineHeight="24px">
                        Best Paper Award at <ISPD/> 2017.
                    </Text>
                    <Text color="#BBB" fontWeight="400" fontSize="18px" margin="0 0 0 15px" lineHeight="24px">
                        Merit-based Fellowship at Cornell.
                    </Text>
                    <Text color="#BBB" fontWeight="400" fontSize="18px" margin="0 0 0 15px" lineHeight="24px">
                        Full Studentship at CUHK.
                    </Text>
                    <Text color="#BBB" fontWeight="400" fontSize="18px" margin="0 0 0 15px" lineHeight="24px">
                        Talents Project at <Link href="https://www.scu.edu.cn/wyzxy/" isExternal color="teal.300">WuYuZhang Honors College</Link> of SCU (Top 0.1%).
                    </Text>
                </Box>
            </Box >
		</Center>
	)
}

export default SelectedAwards;